import { Box, Button } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import { generateCsv, download, mkConfig } from "export-to-csv"
import {
  createMRTColumnHelper,
  useMantineReactTable,
  MantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { ExploreResponses } from "@costory/types/endpoints/explorer"

import { TrendBadge } from "@costory/front/components/TrendBadge"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { CURRENCY_FILTER_VARIANT } from "@costory/front/utils/table"

type Props = {
  data: ExploreResponses.Waterfall["dataGroupedByValue"]
  drillDownInto: (value: string) => void
  currency: "USD" | "EUR"
}

export const TableChart = ({ data, drillDownInto, currency }: Props) => {
  const navigate = useNavigate()

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  })
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data)
    download(csvConfig)(csv)
  }

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<
        ExploreResponses.Waterfall["dataGroupedByValue"][0]
      >()

    return [
      columnHelper.accessor("groupByValue", {
        header: "Value",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <Button
            style={{ userSelect: "text" }}
            onClick={() => drillDownInto(cell.getValue())}
            variant="transparent"
          >
            {cell.getValue()}
          </Button>
        ),
      }),
      columnHelper.accessor("periodCost", {
        header: "Cost",
        ...CURRENCY_FILTER_VARIANT,
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("previousPeriodCost", {
        header: "Previous Period",
        ...CURRENCY_FILTER_VARIANT,
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("change", {
        header: "Change (Absolute)",
        ...CURRENCY_FILTER_VARIANT,
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("relativeChange", {
        header: "Relative change (%)",
        ...CURRENCY_FILTER_VARIANT,
        Cell: ({ cell, row }) => (
          <Button
            variant="transparent"
            onClick={() => {
              navigate("/waterfall")
              drillDownInto(cell.row.original.groupByValue)
            }}
          >
            <TrendBadge
              current={row.original.periodCost}
              previous={row.original.previousPeriodCost}
            />
          </Button>
        ),
      }),
    ]
  }, [drillDownInto, currency, navigate])

  const table = useMantineReactTable<
    ExploreResponses.Waterfall["dataGroupedByValue"][0]
  >({
    columns,
    data,
    enableToolbarInternalActions: false,
    initialState: {
      sorting: [{ id: "periodCost", desc: true }],
    },
    renderTopToolbarCustomActions: () => (
      <Box>
        <Button
          onClick={handleExportData}
          leftSection={<IconDownload />}
          variant="filled"
        >
          Export to CSV{" "}
        </Button>
      </Box>
    ),
  })

  return <MantineReactTable table={table} />
}
