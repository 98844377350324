import { Paper } from "@mantine/core"
import { useParams } from "react-router-dom"

import { Filters } from "@costory/types/filters"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useSavedViewQuery } from "@costory/front/queries/savedViews"
import { getBestDrillDown } from "@costory/front/utils/columns"

type Props = {
  filters: Filters
  drillDownInto: (value: string) => void
}

const Waterfall = ({ filters, drillDownInto }: Props) => (
  <Paper px={32} h={595} py={40}>
    <WaterfallChart
      filters={filters}
      height={500}
      drillDownInto={drillDownInto}
    />
  </Paper>
)

export const WaterfallPage = () => {
  const { viewId } = useParams()
  const savedViewQuery = useSavedViewQuery(viewId)

  if (viewId) {
    return (
      <QueryWrapper query={savedViewQuery}>
        {({ data: savedView }) => (
          <ChartContainer
            redirectPage="waterfall"
            savedView={savedView}
            key={viewId}
          >
            {({ filters, drillDownInto }) => (
              <Waterfall
                filters={filters}
                drillDownInto={(value) =>
                  drillDownInto(
                    filters.groupBy,
                    value,
                    getBestDrillDown(filters.groupBy),
                  )
                }
              />
            )}
          </ChartContainer>
        )}
      </QueryWrapper>
    )
  }
  return (
    <ChartContainer redirectPage="waterfall" key="no-view" defaultHideOneTime>
      {({ filters, drillDownInto }) => (
        <Waterfall
          filters={filters}
          drillDownInto={(value) =>
            drillDownInto(
              filters.groupBy,
              value,
              getBestDrillDown(filters.groupBy),
            )
          }
        />
      )}
    </ChartContainer>
  )
}
