import {
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  SegmentedControl,
  Select,
  Stack,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { IconDeviceFloppy, IconEdit, IconLink } from "@tabler/icons-react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { Filters } from "@costory/types/filters"

import { DashboardType } from "@costory/front/components/dashboard/constants"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAuthState } from "@costory/front/queries/auth"
import {
  useListSavedViewsQuery,
  useCreateSavedViewMutation,
  useUpdateSavedViewMutation,
  SavedViewRedirectPage,
} from "@costory/front/queries/savedViews"
import { formatSavedViews } from "@costory/front/utils/filters"

type Props = {
  currentView?: SavedViewsResponses.SavedView
  redirectPage: SavedViewRedirectPage
  form: UseFormReturnType<Filters>
}

type SaveViewMode = "create" | "update"

export const SavedViewsSelector = ({
  currentView,
  redirectPage,
  form,
}: Props) => {
  const navigate = useNavigate()
  const [isOpen, { close, open }] = useDisclosure()
  const auth = useAuthState()
  const [viewName, setViewName] = useState(currentView?.name ?? "")
  const [viewType, setViewType] = useState(currentView?.type ?? "PUBLIC")
  const [saveMode, setSaveMode] = useState<SaveViewMode>("create")

  const savedViewsQuery = useListSavedViewsQuery()
  const { mutateAsync: createSavedView, isPending: isCreatingSavedView } =
    useCreateSavedViewMutation(redirectPage)
  const { mutateAsync: updateSavedView, isPending: isUpdatingSavedView } =
    useUpdateSavedViewMutation(currentView?.id)

  const handleSelectView = (viewId: string | null) => {
    if (viewId) {
      navigate(`/${redirectPage}/views/${viewId}`)
    }
  }

  const handleShareLink = async () => {
    navigator.clipboard.writeText(window.location.href)
    showNotification({
      color: "green",
      title: "Url saved !",
      message: "Url saved to clipboard",
    })
  }

  const handleOpenSaveViewModal = (mode: SaveViewMode) => {
    setSaveMode(mode)
    if (currentView) {
      if (mode === "update") {
        setViewName(currentView.name)
      } else {
        setViewName(`${currentView.name} - Copy`)
      }
    }
    open()
  }

  const handleSaveView = async () => {
    try {
      const method = saveMode === "create" ? createSavedView : updateSavedView
      await method({ name: viewName, type: viewType, ...form.getValues() })
      close()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <QueryWrapper query={savedViewsQuery} allowEmptyArray>
        {({ data: savedViews }) => (
          <Group>
            {savedViews.length > 0 && (
              <Select
                clearable
                searchable
                placeholder="Select a saved view"
                value={currentView?.id}
                onChange={handleSelectView}
                data={formatSavedViews(savedViews)}
              />
            )}
            <ActionIcon
              variant="transparent"
              color="var(--mantine-primary-color-8)"
              size="lg"
            >
              <IconLink onClick={handleShareLink} />
            </ActionIcon>
            <Button.Group>
              <Button
                variant={currentView ? "outline" : "filled"}
                onClick={() => handleOpenSaveViewModal("create")}
                leftSection={<IconDeviceFloppy />}
              >
                {currentView ? "Save as new" : "Create view"}
              </Button>
              {currentView &&
                (currentView.createdById == auth.user?.id ||
                  auth.user?.isAdmin) && (
                  <Button
                    onClick={() => handleOpenSaveViewModal("update")}
                    leftSection={<IconEdit />}
                  >
                    Update view
                  </Button>
                )}
            </Button.Group>
          </Group>
        )}
      </QueryWrapper>
      <Modal
        opened={isOpen}
        onClose={close}
        title={
          saveMode === "create"
            ? "Save a new view"
            : `Update view ${currentView?.name}`
        }
      >
        <Stack>
          <Input
            flex={1}
            value={viewName}
            onChange={(e) => setViewName(e.currentTarget.value)}
          />
          <SegmentedControl
            value={viewType}
            onChange={(e) => setViewType(e as DashboardType)}
            data={[
              { label: "Public", value: "PUBLIC" },
              { label: "Private", value: "PRIVATE" },
            ]}
          />
          <Button
            onClick={handleSaveView}
            loading={isCreatingSavedView || isUpdatingSavedView}
            disabled={!viewName}
          >
            Save
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
