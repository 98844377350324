import {
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  SegmentedControl,
  Stack,
  Switch,
  Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import {
  IconDeviceFloppy,
  IconEdit,
  IconPencil,
  IconPlus,
  IconX,
} from "@tabler/icons-react"
import { useState } from "react"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"

import { DashboardType } from "@costory/front/components/dashboard/constants"
import { useAuthState } from "@costory/front/queries/auth"

import { DashboardSelector } from "./DashboardSelector"

type Props = {
  isEditing: boolean
  isCreating: boolean
  isCreatingDashboard: boolean
  isUpdatingDashboard: boolean
  isDashboardEmpty: boolean
  onSave: (
    mode: string,
    name: string,
    type: DashboardType,
    defaultDashboard: boolean,
  ) => void
  updateDashboardMode: (value: boolean, mode?: "editing" | "creating") => void
  onAddWidget: () => void
  currentDashboard: DashboardResponses.DashboardT
}
type SaveDashboardMode = "create" | "update"
export const DashboardActionBar = ({
  isEditing,
  isCreating,
  isCreatingDashboard,
  isUpdatingDashboard,
  isDashboardEmpty,
  onSave,
  updateDashboardMode,
  onAddWidget,
  currentDashboard,
}: Props) => {
  const [isOpen, { close, open }] = useDisclosure()
  const [saveMode, setSaveMode] = useState<SaveDashboardMode>("update")
  const [dashboardName, setDashboardName] = useState(currentDashboard.name)
  const [dashboardType, setDashboardType] = useState<DashboardType>(
    currentDashboard.type,
  )
  const [defaultDashboard, setDefaultDashboard] = useState<boolean>(
    currentDashboard.defaultDashboard,
  )

  const handleOpenSaveDashboardModal = (mode: SaveDashboardMode) => {
    setSaveMode(mode)
    if (currentDashboard) {
      if (mode === "update") {
        setDashboardName(currentDashboard.name)
      } else {
        setDashboardName(`${currentDashboard.name} - Copy`)
      }
    }
    open()
  }

  const handleSave = (saveMode: SaveDashboardMode) => {
    close()
    onSave(saveMode, dashboardName, dashboardType, defaultDashboard)
  }
  const user = useAuthState()
  return (
    <>
      <Group justify="flex-end">
        {isEditing || isCreating ? (
          <>
            <Button
              variant="outline"
              leftSection={<IconPlus size={20} />}
              onClick={onAddWidget}
            >
              Add widget
            </Button>
            <Button.Group>
              <Button
                loading={isCreatingDashboard}
                variant="outline"
                onClick={() => handleOpenSaveDashboardModal("create")}
                leftSection={<IconDeviceFloppy size={20} />}
                disabled={isDashboardEmpty}
              >
                Save as new
              </Button>
              {currentDashboard.id &&
              (currentDashboard.createdById === user.user?.id ||
                user.user?.isAdmin) &&
              isEditing ? (
                <Button
                  loading={isUpdatingDashboard}
                  onClick={() => handleOpenSaveDashboardModal("update")}
                  leftSection={<IconEdit size={20} />}
                  disabled={isDashboardEmpty}
                >
                  Update Dashboard
                </Button>
              ) : null}
            </Button.Group>
            <ActionIcon
              variant="outline"
              size="lg"
              onClick={() => {
                // TODO : Need to handle the senarion:
                // - when I haven't modified anything in which case this is an extra click.
                // - I redirect to a different dashboard when editing NOT the default one which is a regression and need to be removed.
                modals.openConfirmModal({
                  title: "Please confirm your action",
                  children: (
                    <Text size="sm">
                      {" "}
                      Are you sure you want to discard your changes?
                    </Text>
                  ),
                  labels: { confirm: "Confirm", cancel: "Cancel" },
                  onConfirm: () => {
                    if (updateDashboardMode) {
                      updateDashboardMode(false)
                    }
                  },
                })
              }}
            >
              <IconX />
            </ActionIcon>
          </>
        ) : (
          <>
            <DashboardSelector currentDashboard={currentDashboard} />
            <Button.Group>
              <Button
                leftSection={<IconDeviceFloppy size={20} />}
                variant="outline"
                onClick={() => updateDashboardMode(true, "creating")}
              >
                Create New Dashboard
              </Button>
              {currentDashboard.dashboardWidgets?.length !== 0 && (
                <Button
                  leftSection={<IconPencil size={20} />}
                  onClick={() => updateDashboardMode(true, "editing")}
                >
                  Edit Dashboard
                </Button>
              )}
            </Button.Group>
          </>
        )}
      </Group>
      <Modal
        opened={isOpen}
        onClose={close}
        title={
          saveMode === "create"
            ? "Save a new dashboard"
            : `Update dashboard ${currentDashboard?.name}`
        }
      >
        <Stack>
          <Input
            flex={1}
            value={dashboardName}
            onChange={(e) => setDashboardName(e.currentTarget.value)}
          />
          <SegmentedControl
            value={dashboardType}
            onChange={(e) => setDashboardType(e as DashboardType)}
            data={[
              { label: "Public", value: "PUBLIC" },
              { label: "Private", value: "PRIVATE" },
            ]}
          />
          <Group>
            <Switch
              checked={defaultDashboard}
              size="xl"
              onChange={(event) =>
                setDefaultDashboard(event.currentTarget.checked)
              }
              onLabel="Default"
            />{" "}
            Set as Default
          </Group>
          <Button
            onClick={() => handleSave(saveMode)}
            loading={isCreatingDashboard || isUpdatingDashboard}
            disabled={!dashboardName}
          >
            Save
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
