import {
  Button,
  Card,
  Title,
  List,
  ListItem,
  Flex,
  Grid,
  Loader,
} from "@mantine/core"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Key, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { apiClient } from "@costory/front/lib/apiClient"
import { SlackButton } from "@costory/front/pages/Integration/components/SlackButton"

const SlackSettings = () => {
  const { mutateAsync: sendMessage } = useMutation({
    mutationFn: async (channelID: string) => {
      await apiClient.post("/slack/send-message", { channelID })
    },
    onError: (error) => {
      console.log(error.message)
    },
  })

  const { data: slackChannels, isLoading } = useQuery({
    queryKey: ["slack settings"],
    queryFn: async () => {
      const response = await apiClient.get("/slack/channels")
      return response.data
    },
    enabled: true, // Always fetch channels
  })

  const channels = slackChannels?.channels || []
  const url = slackChannels?.url

  if (isLoading) {
    return (
      <Flex align="center" justify="center" p={32} h="100%">
        <Loader />
      </Flex>
    )
  }

  return (
    <Card>
      <Grid>
        <Grid.Col span={12} m="md">
          {channels.length === 0 && <SlackButton url={url} />}
          {channels.length > 0 && <Title order={3}>List of Channels</Title>}
          <List ml="xs">
            {channels.map((channel: { id: Key; name: string }) => (
              <ListItem key={channel.id} mt="xs" mb="xs">
                <Flex>
                  <Flex miw={200} maw={250} direction="row" wrap="wrap">
                    {channel.name}
                  </Flex>
                  <Flex>
                    <Button onClick={() => sendMessage(channel.id as string)}>
                      Send Message
                    </Button>
                  </Flex>
                </Flex>
              </ListItem>
            ))}
          </List>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export const IntegrationPage = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const urlParams = new URLSearchParams(location.search)
  const code = urlParams.get("code")
  const state = urlParams.get("state")
  const navigate = useNavigate()

  const { refetch } = useQuery({
    queryKey: ["slack settings"],
    queryFn: async () => {
      const response = await apiClient.get("/slack/channels")
      return response.data
    },
    enabled: true, // Enable the query
  })

  useEffect(() => {
    const fetchToken = async () => {
      if (code) {
        setLoading(true)
        try {
          // Step 1: Fetch OAuth token
          const response = await apiClient.get(
            `/slack/oauth_redirect?code=${code}&state=${state}`,
          )

          // Step 2: Call refetch only if OAuth token fetch was successful
          if (response?.status === 200) {
            // We need to explicity wait as the response from secret creation takes time in backend but resolve happens earlier.
            await new Promise((resolve) => setTimeout(resolve, 3000)) // 3-second delay
            await refetch()
            // Step 3: Navigate after refetch is completed
            navigate("/slack-integration")
          }
        } catch (error) {
          console.error("Error fetching Slack token:", error)
        } finally {
          setLoading(false)
        }
      }
    }
    fetchToken()
  }, [code, state, navigate, refetch])

  if (loading) {
    return (
      <Flex align="center" justify="center" p={32} h="100%">
        <Loader />
      </Flex>
    )
  }

  return <SlackSettings />
}
